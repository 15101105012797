import { FaLinkedinIn, FaXTwitter, FaTelegram } from "react-icons/fa6";
import { Link } from "react-router-dom";

function Footer() {
    return (
        <footer className="bottom-footer">
            <div className="container">
                <div className="row ">
                    <div className="col-md-6 col-sm-6 mt-5">
                        <Link className="navbar-brand" to="/">
                            <img src="./theme/images/loggtop.png" className="logo" alt="krabs" />
                        </Link>
                        <p className="footer-text"></p>
                        <p className="copy-right">©2024,All Rights Reserved</p>
                    </div>
                    <div className="col-md-6 col-sm-6 mt-5 text-end">
                        <p className="join-footer">Join the community</p>
                        <div className="social-icons">
                            <div className="around_social">
                                <a href="https://twitter.com/mrkrabssolana" target="_blank">
                                    <FaXTwitter className="icons_clr" />
                                </a>
                            </div>
                            <div className="around_social">
                                <a href="https://t.me/KrabsSOL_Official" target="_blank">
                                    <FaTelegram className="icons_clr" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;