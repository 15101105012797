import React, { useEffect, useState } from "react";
import Tab from "./Tab_page";
import Web3 from "web3";
import AOS from 'aos';
import {
    Presale_Contract_ABI,
    Presale_Contract_Address,
    staking_Contract_ABI,
    staking_Contract_Address,
} from "./Contract/Contract";
import { useAccount } from "wagmi";
import Countdown from "react-countdown";
import { FaLinkedinIn, FaXTwitter, FaTelegram } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import Footer from './Include/Footer';

function Landing_page({ setRaise }) {
    const [getTokenToUSDT, setgetTokenToUSDT] = useState(0);
    const [get_tokeSold, setget_tokeSold] = useState(0);
    const [get_maxTokeninPresale, setget_maxTokeninPresale] = useState(0);
    const { address } = useAccount();
    const [phaseNewPrice, setPhasePrice] = useState(0);
    const [NextphaseNewPrice, setNextPhasePrice] = useState(0);
    const [CurrentStage, setCurrentStage] = useState(null);
    const [Rratio, setRatio] = useState(0);
    const [totalRaisedinContract, settotalRaisedinContract] = useState(0);

    const [IsClaim, setIsClaim] = useState(false);
    const [Claimable, setClaimable] = useState(0);

    const EThwebSupply = new Web3("wss://ethereum-rpc.publicnode.com");

    const webSupply = new Web3(
        "https://bsc-rpc.publicnode.com"

        // https://bsc-testnet.publicnode.com

        // https://bsc-rpc.publicnode.com
    );

    const getValue = async () => {
        try {
            let ContractOf = new webSupply.eth.Contract(
                staking_Contract_ABI,
                staking_Contract_Address
            );
            const BSCcontractOf = new webSupply.eth.Contract(
                staking_Contract_ABI,
                staking_Contract_Address
            );
            let ETHcontractOf = new EThwebSupply.eth.Contract(
                Presale_Contract_ABI,
                Presale_Contract_Address
            );
            let tokenToUSDT = await ContractOf.methods.TokenPricePerUsdt().call();
            tokenToUSDT = webSupply.utils.fromWei(tokenToUSDT.toString());
            setgetTokenToUSDT(tokenToUSDT);

            let TokenSold = await ContractOf.methods.TokenSold().call();
            TokenSold = TokenSold / 1000000000
            TokenSold = TokenSold.toString()
            TokenSold = Number(TokenSold);

            setRaise(Intl.NumberFormat().format(Number(TokenSold) * Number(0.2)))

            // TokenSold = Intl.NumberFormat().format(TokenSold);
            setget_tokeSold(TokenSold);

            let BSCtokensold = await BSCcontractOf.methods.TokenSold().call();
            let BSCTokenPricePerusdt = await BSCcontractOf.methods
                .TokenPricePerUsdt()
                .call();
            BSCtokensold = (BSCtokensold / 1000000000).toString();
            BSCTokenPricePerusdt = webSupply.utils.fromWei(BSCTokenPricePerusdt.toString());
            let BSCusdtRaised = BSCtokensold / BSCTokenPricePerusdt;

            let ETHtokensold = await ETHcontractOf.methods.TokenSold().call();
            let ETHTokenPricePerusdt = await ETHcontractOf.methods
                .TokenPricePerUsdt()
                .call();
            ETHtokensold = (ETHtokensold / 1000000000).toString();
            ETHTokenPricePerusdt = webSupply.utils.fromWei(ETHTokenPricePerusdt.toString());
            let ETHusdtRaised = ETHtokensold / ETHTokenPricePerusdt;
            settotalRaisedinContract(Number(ETHusdtRaised) + Number(BSCusdtRaised));

            let CurrentStage = await ContractOf.methods.currentPhase().call();
            // currentPhasePricee = currentPhasePricee.price;
            // console.log("CurrentStage",CurrentStage);
            setCurrentStage(CurrentStage);
            // getCurrentPhasePrice

            // Get Current Stage Price
            let phases = await ContractOf.methods.phases(CurrentStage).call();
            console.log(phases, "phases");
            let phasePrice = phases.price / 1e18;
            let newPhase = (1 / phasePrice)
            setPhasePrice(newPhase);
            console.log(newPhase, "newPhase");
            setget_maxTokeninPresale((phases.maxTokens / 1e18) - TokenSold);
            // console.log("Current phase price",newPhase);

            // 1$ = 5 token
            //  1 token = 1/5

            setRaise(Intl.NumberFormat().format(Number(TokenSold) * Number(phasePrice)))
            //Get Next Stage Price
            let SCurrentStage = Number(CurrentStage) + 1;
            // console.log("S current",SCurrentStage);
            let Nextphases = await ContractOf.methods.phases(SCurrentStage).call();
            // console.log(Nextphases);
            let NextphasePrice = Nextphases.price / 1e18;
            let TNextPhasePrice = (1 / NextphasePrice).toFixed(5);
            setNextPhasePrice(TNextPhasePrice);
        } catch (error) {
            console.log(error);
        }
    };

    //  function getCurrentPhasePrice() internal view returns (uint256) {
    // return phases[currentPhase].price;
    // }

    const Claim_status = async () => {
        let contractOf = new webSupply.eth.Contract(
            staking_Contract_ABI,
            staking_Contract_Address
        );
        let CanClaim = await contractOf.methods.CanClaim().call();
        // console.log("CanClaim", CanClaim);
        setIsClaim(CanClaim);
        if (address) {
            //  let address = "0x7f269c43BA2BFC891602fc3222c60b2D5c807d56"
            let Claimable = await contractOf.methods.Claimable(address).call();
            // Claimable = webSupply.utils.fromWei(Claimable.toString());
            Claimable = Claimable / 1000000000
            Claimable = Claimable.toString()
            // console.log("Claimable",Claimable);

            if (Claimable > 0) {
                setClaimable(Claimable);
            } else {
                setClaimable(0);
            }
        }
    };

    useEffect(() => {
        Claim_status();
    }, []);


    useEffect(() => {
        getValue();
    }, [address]);

    // Set get_maxTokeninPresale to 6,000,000
    let Gget_maxTokeninPresale = 6000000; //MaxToken in presale
    if (get_maxTokeninPresale !== 0) {
        const ratio = (get_tokeSold / Gget_maxTokeninPresale) * 100;
        // setRatio(ratio);
    } else {
        // console.log("Cannot calculate ratio, getMaxTokeninPresale is zero");
    }


    // Raised

    const formattedValue = Intl.NumberFormat().format(get_tokeSold * 0.2);


    const Completionist = () => {
        return (
            <>
                <div className="text_days fs-5 ">Date of Sale TBA</div>
            </>
        );
    };

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        // console.log("renderer",items);
        if (completed) {
            return <Completionist />;
        } else {
            return (
                <div className="text_days fs-5 ">
                    <div className="row mt-3 timer_pre">
                        <div className="col-3 sm_pd">
                            <div className="brd_right padss">
                                <div className="d-flex  flex-column justify-content-center text-center">
                                    <h4 className="timer_tex mb-0">{days}</h4>
                                    <p className="timer_tex mb-0">Days</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 sm_pd">
                            <div className="brd_right padss text-center d-flex justify-content-center">
                                <div className="">
                                    <h4 className="timer_tex mb-0">{hours}</h4>
                                    <p className="timer_tex mb-0">Hours</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 sm_pd">
                            <div className="brd_right padss">
                                <div className="d-flex flex-column justify-content-center text-center">
                                    <h4 className="timer_tex mb-0">{minutes}</h4>
                                    <p className="timer_tex mb-0">Minutes</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 sm_pd">
                            <div className="d-flex flex-column justify-content-center text-center">
                                <h4 className="timer_tex mb-0">{seconds}</h4>
                                <p className="timer_tex mb-0">Seconds</p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    useEffect(() => {
        AOS.init({
            duration: 800,
        })
    }, [])

    return (
        <>
            <section className="top-section">
                <div className="container d-flex align-items-center" style={{ height: "100%" }}>
                    <div className="row align-items-center">
                        <div className="col-sm-7">
                            <div className="row align-items-center" id="top">
                                <div className="col-md-12 col-sm-6">
                                    <h1 className="kabby">Go Krabby or <br />Go Home </h1>
                                    <p>There’s Always Treasure in the Meme Stand: Get Krabby Cash!</p>
                                    <div className="button-text">
                                        <a href="#" className="tokeno">Tokenomics</a>
                                        <a href="#" className="Roadmaps">Roadmaps</a>
                                    </div>
                                </div>
                                <div className="cols-md-12 col-sm-6" id="join-category">
                                    <div className="join">
                                        <p>Join the community</p>
                                        <div className="social-icons">
                                            <div className="around_social">
                                                <a href="https://twitter.com/mrkrabssolana" target="_blank">
                                                    <FaXTwitter className="icons_clr" />
                                                </a>
                                            </div>
                                            <div className="around_social">
                                                <a href="https://t.me/KrabsSOL_Official" target="_blank">
                                                    <FaTelegram className="icons_clr" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <div className="boxed">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <p className="current">Current stage</p>
                                        <p className="stage">Stage {Number(CurrentStage) + 1}</p>
                                    </div>
                                    <div className="col-sm-6">
                                        <p className="current text-end">Remaining Tokens</p>
                                        <p className="stage text-end">{Intl.NumberFormat().format(get_maxTokeninPresale)} $KRBS</p>
                                    </div>
                                </div>
                                <div className="progress pogress-half">
                                    <div className="progress-bar" role="progressbar" style={{ width: `${(get_tokeSold / get_maxTokeninPresale) * 100}%` }} aria-valuenow={(get_tokeSold / get_maxTokeninPresale) * 100} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <p className="current">Next stage price</p>
                                        <p className="stage">{NextphaseNewPrice}</p>
                                    </div>
                                    <div className="col-sm-6">
                                        <p className="current text-end">Total Token Sold</p>
                                        <p className="stage text-end">{Intl.NumberFormat().format(get_tokeSold)}</p>
                                    </div>
                                </div>
                                <div className="row timer">
                                    <Countdown
                                        date={
                                            Date.now() +
                                            (parseInt("1714687361") * 1000 - Date.now())
                                        }
                                        renderer={renderer}
                                    />
                                </div>
                                <div className="buy-button mt-3">
                                    <a href="#" className="link-buy">Buy before price increase!</a>
                                </div>
                                <div className="rpize">
                                    <p className="text-center"><strong>$ {phaseNewPrice}= 1 $KRBS</strong></p>
                                </div>
                                <Tab />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bottom-image-section">
                <div className="container">
                    <div className="row top-whitepaper align-items-center gap-4">
                        <div className="col-md-1"></div>
                        <div className="col-md-5 col-sm-6">
                            <div className="row align-items-center">
                                <div className="col-md-9 col-sm-9">
                                    <p className="white-paper">White paper</p>
                                    <p className="undetaking">Download our whitepaper to get a detailed
                                        understanding of $KRBSstaking
                                    </p>
                                </div>
                                <div className="col-md-3 col-sm-3">
                                    <a href="https://mrkrabs.io/wp-content/uploads/2024/04/whitepaper-for-Mr.krabs-coinkrbs.pdf" target="_blank">
                                        <img src="./theme/images/right-icon.png" alt="krabs" className="float-end" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-6">
                            <div className="row align-items-center">
                                <div className="col-md-9 col-sm-8">
                                    <p className="white-paper">AUDIT REPORT</p>
                                    <p className="undetaking">The smart contract has been audited by blockchain security specialist Solid Proof.</p>
                                </div>
                                <div className="col-md-3 col-sm-3">
                                    <a href="javascript:void(0);">
                                        <img src="./theme/images/right-icon.png" alt="krabs" className="float-end" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                    <div className="row mt-4 second-box gap-4">
                        <div className="col-md-4 col-sm-4">
                            <p className="text-center price-paper">10</p>
                            <p className="text-center text-cion">Total Stages</p>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <p className="text-center price-paper">${Intl.NumberFormat().format(totalRaisedinContract)}</p>
                            <p className="text-center text-cion">Amount Raised</p>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <p className="text-center price-paper">{Intl.NumberFormat().format(get_maxTokeninPresale)}</p>
                            <p className="text-center text-cion">Total token availible for sale</p>
                        </div>
                    </div>
                    <div className="row third-box align-items-center  mt-5 ">
                        <div className="col-md-5 col-sm-5">
                            <p className="secure text-end">100% Secure</p>
                        </div>
                        <div className="col-md-1 col-sm-1"></div>
                        <div className="col-md-6 col-sm-6">
                            <p className="border-text">The smart contract has been audited by blockchain security specialist
                                Solid Proof.
                            </p>
                        </div>
                    </div>
                    <div className="image-box mt-3 ">
                        <div className="row gap-4">
                            <div className="col-md-6 col-sm-6">
                                <img src="./theme/images/moneey.png" alt="krabs" className="imaged" />
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <img src="./theme/images/monney2.png" alt="krabs" className="float-end imaged" />
                            </div>
                        </div>
                    </div>
                    <div className="buy-purchage mt-5">
                        <p className="text-center buy-scres">HOW TO BUY $KRBS ?</p>
                        <p className="text-center buy-scres-text">You don't want to miss that, so take this chance and don't let the past repeat itself. Follow the steps below to make a purchase.</p>
                        <div className="row gap-4">
                            <div className="col-md-4">
                                <p className="text-center connect-text">Connect</p>
                                <p className="download-malket">You'll need a wallet to participate; you can download MetaMask or Trust wallet. Open this page in the wallet's Dapp browser, then tap on the connect button and tap MetaMask. If you prefer using other wallet apps, mobile phone wallets or browsers, you’re equally covered! Just connect via the Wallet Connect option.</p>
                            </div>
                            <div className="col-md-4">
                                <p className="text-center connect-text">Purchase</p>
                                <p className="download-malket">Buy with BNB or Ethereum Enter the quantity of BNB or Ethereum tokens to buy and click the purchase button. A quick reminder to keep enough BNB or Ethereum in your wallet to pay gas charges to prevent transaction failures.</p>
                            </div>
                            <div className="col-md-4">
                                <p className="text-center connect-text">Claim</p>
                                <p className="download-malket">After the final presale round, you’ll have the exclusive chance to claim your tokens before we launch on Coinbase, Binance, and other exchange platforms. We’ll notify you of the claiming and launch dates.</p>
                            </div>
                        </div>
                    </div>
                    <div className="roadmap mt-5">
                        <p className="text-center roadmap-text">Roadmap</p>
                        <div className="row align-items-center " id="fadeSection">
                            <div className="col-md-4 col-sm-4 left-fade">
                                <div data-aos="fade-left">
                                    <img src="./theme/images/baby.png" alt="krabs" className="left-fade" />
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-1"></div>
                            <div className="col-md-7 col-sm-7 right-fade">
                                <div data-aos="fade-left">
                                    <p className="pahse-text ">Phase 1: (Q2 2024)</p>
                                    <p className="ethereum ">– Launch: Mrkrabs is immediately on Ethereum and BNB<br />
                                        – $KRBS Presale: Easy to get in presale, before Mr.Krabs locks his safe<br />
                                        – Krabs Community: Build the Krabby Crew (community engagement, partnerships)<br />
                                        – Whitepaper: Release the detailed whitepaper (“Discover the Treasure Map”)<br />
                                        – Solana: Add $KRBS to Solana.– Staking: Hold onto your Mrkrabs and watch it grow.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 col-sm-2"></div>
                            <div className="col-md-8 col-sm-8">
                                <img src="./theme/images/Vector.png" alt="krabs" className="vector-image" />
                            </div>
                            <div className="col-md-2 col-sm-2"></div>
                        </div>
                        <div className="row align-items-center ">
                            <div className="col-md-7 col-sm-7 left-fade">
                                <div data-aos="fade-left">
                                    <p className="pahse-text">Phase 2: (Q3-Q4 2024)</p>
                                    <p className="ethereum">– Spread the Word: Tell your shipmates, the $KRBS is making waves.<br />
                                        – Grow your Holdings: More coins in your chest means more treasure to spend.<br />
                                        – List $KRBS on major exchanges (“Increase accessibility”).
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-1"></div>
                            <div className="col-md-4 col-sm-4 right-fade">
                                <div data-aos="fade-right">
                                    <img src="./theme/images/phase-22.png" alt="krabs" className="vector-image" />
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-2"></div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 col-sm-2"></div>
                            <div className="col-md-8 col-sm-8 right-fade">
                                <img src="./theme/images/Vector2.png" alt="krabs" className="vector-image" />
                            </div>
                            <div className="col-md-2 col-sm-2"></div>
                        </div>
                        <div className="row align-items-center ">
                            <div className="col-md-4 col-sm-4">
                                <div data-aos="fade-left">
                                    <img src="./theme/images/phase-33.png" alt="krabs" />
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-1"></div>
                            <div className="col-md-7 col-sm-7 left-fade">
                                <div data-aos="fade-right">
                                    <p className="pahse-text">Phase 3: (Q1-Q4 2025)</p>
                                    <p className="ethereum">– Listing: Add $KRBS to top exchanges (Coinbase, Binance, etc.).<br />
                                        – Rule the Market: take $KRBS to the top, where Mr.Krabs dreams of being.<br />
                                        – Coin Quests: Complete tasks, earn more $KRBS, simple as that.<br />
                                        – Hold the Helm: Steer your fate with $KRBS, and find your fortune.)<br />
                                        – ⁠Control the meme world
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="testimonila-section mt-5">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 left-setion">
                                    <span>Core features and Visions</span>
                                    <p>Transacational Dynamics Of the $KRBS token</p>
                                    <div className="testtimonila-cions">
                                        <img src="./theme/images/left-cion.png" alt="left" />
                                        <img src="./theme/images/right-icon.png" alt="right-icon" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 right-section">
                                    <div className="row testimonila-data justify-content-end">
                                        <div className="col-sm-5 col-md-5">
                                            <div className="svg-icons float-end">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="93" height="93" viewBox="0 0 93 93" fill="none">
                                                    <path d="M46.8044 0C55.9409 0 64.8718 2.70912 72.4685 7.78509C80.0653 12.8611 85.9868 20.076 89.4832 28.517C92.9796 36.958 93.8942 46.2462 92.1117 55.2071C90.3293 64.1681 85.9296 72.3993 79.4691 78.8597C73.0086 85.3202 64.7775 89.7199 55.8165 91.5024C46.8556 93.2848 37.5674 92.3702 29.1264 88.8738C20.6853 85.3774 13.4704 79.4563 8.39447 71.8596C3.3185 64.2629 0.609375 55.3315 0.609375 46.195C0.609375 33.9433 5.47639 22.1935 14.1396 13.5303C22.8029 4.86701 34.5527 0 46.8044 0Z" fill="white" fill-opacity="0.07" />
                                                    <path d="M46.8044 3.51962C41.042 3.51962 35.4533 4.64743 30.1945 6.87168C25.1127 9.02117 20.549 12.0987 16.6281 16.0187C12.7081 19.9392 9.63012 24.5033 7.48105 29.5846C5.25723 34.8439 4.12899 40.4322 4.12899 46.195C4.12899 51.9578 5.25723 57.5461 7.48105 62.8053C9.63012 67.8867 12.7081 72.4508 16.6281 76.3713C20.549 80.2913 25.1127 83.3688 30.1945 85.5183C35.4533 87.7426 41.042 88.8704 46.8044 88.8704C52.5667 88.8704 58.1555 87.7426 63.4143 85.5183C68.4961 83.3688 73.0597 80.2913 76.9806 76.3713C80.9007 72.4508 83.9786 67.8867 86.1277 62.8053C88.3515 57.5461 89.4798 51.9578 89.4798 46.195C89.4798 40.4322 88.3515 34.8439 86.1277 29.5846C83.9786 24.5033 80.9007 19.9392 76.9806 16.0187C73.0597 12.0987 68.4961 9.02117 63.4143 6.87168C58.1555 4.64743 52.5667 3.51962 46.8044 3.51962ZM46.8044 0C72.3173 0 92.9994 20.6821 92.9994 46.195C92.9994 71.7079 72.3173 92.39 46.8044 92.39C21.2914 92.39 0.609375 71.7079 0.609375 46.195C0.609375 20.6821 21.2914 0 46.8044 0Z" fill="url(#paint0_linear_216_20)" fill-opacity="0.46" />
                                                    <path d="M48.2481 18.8945L54.843 25.6276L38.2375 42.1838L31.6426 35.6127L48.2481 18.8945Z" fill="url(#paint1_linear_216_20)" />
                                                    <path d="M58.2597 28.8762L64.8546 35.6093L38.2375 62.1463L31.6426 55.5716L58.2597 28.8762Z" fill="url(#paint2_linear_216_20)" />
                                                    <path d="M28.2259 38.8569L34.8208 45.59L28.2259 52.1646L21.6328 45.59L28.2259 38.8569Z" fill="url(#paint3_linear_216_20)" />
                                                    <path d="M68.2704 38.8569L74.8653 45.59L48.2482 72.127L41.6533 65.5524L68.2704 38.8569Z" fill="url(#paint4_linear_216_20)" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear_216_20" x1="46.8044" y1="0" x2="46.8044" y2="92.39" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#DE4300" />
                                                            <stop offset="1" stop-color="#FBB625" />
                                                        </linearGradient>
                                                        <linearGradient id="paint1_linear_216_20" x1="43.2428" y1="18.8945" x2="43.2428" y2="42.1838" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#DE4300" />
                                                            <stop offset="1" stop-color="#FBB625" />
                                                        </linearGradient>
                                                        <linearGradient id="paint2_linear_216_20" x1="48.2486" y1="28.8762" x2="48.2486" y2="62.1463" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#DE4300" />
                                                            <stop offset="1" stop-color="#FBB625" />
                                                        </linearGradient>
                                                        <linearGradient id="paint3_linear_216_20" x1="28.2268" y1="38.8569" x2="28.2268" y2="52.1646" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#DE4300" />
                                                            <stop offset="1" stop-color="#FBB625" />
                                                        </linearGradient>
                                                        <linearGradient id="paint4_linear_216_20" x1="58.2593" y1="38.8569" x2="58.2593" y2="72.127" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#DE4300" />
                                                            <stop offset="1" stop-color="#FBB625" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                            <p className="percent-text">4%</p>
                                            <p className="percent">Redistribution in BNB</p>
                                            <p className="text-main">This portion is designed to consistently
                                                reward holders. The choice of BNB, a
                                                cryptocurrency known for its stability and
                                                utility in the Binance ecosystem.
                                            </p>
                                        </div>
                                        <div className="col-sm-1 col-md-1">
                                        </div>
                                        <div className="col-sm-5 col-md-5">
                                            <div className="svg-icons float-end">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="93" height="93" viewBox="0 0 93 93" fill="none" >
                                                    <path d="M46.8044 0C55.9409 0 64.8718 2.70912 72.4685 7.78509C80.0653 12.8611 85.9868 20.076 89.4832 28.517C92.9796 36.958 93.8942 46.2462 92.1117 55.2071C90.3293 64.1681 85.9296 72.3993 79.4691 78.8597C73.0086 85.3202 64.7775 89.7199 55.8165 91.5024C46.8556 93.2848 37.5674 92.3702 29.1264 88.8738C20.6853 85.3774 13.4704 79.4563 8.39447 71.8596C3.3185 64.2629 0.609375 55.3315 0.609375 46.195C0.609375 33.9433 5.47639 22.1935 14.1396 13.5303C22.8029 4.86701 34.5527 0 46.8044 0Z" fill="white" fill-opacity="0.07" />
                                                    <path d="M46.8044 3.51962C41.042 3.51962 35.4533 4.64743 30.1945 6.87168C25.1127 9.02117 20.549 12.0987 16.6281 16.0187C12.7081 19.9392 9.63012 24.5033 7.48105 29.5846C5.25723 34.8439 4.12899 40.4322 4.12899 46.195C4.12899 51.9578 5.25723 57.5461 7.48105 62.8053C9.63012 67.8867 12.7081 72.4508 16.6281 76.3713C20.549 80.2913 25.1127 83.3688 30.1945 85.5183C35.4533 87.7426 41.042 88.8704 46.8044 88.8704C52.5667 88.8704 58.1555 87.7426 63.4143 85.5183C68.4961 83.3688 73.0597 80.2913 76.9806 76.3713C80.9007 72.4508 83.9786 67.8867 86.1277 62.8053C88.3515 57.5461 89.4798 51.9578 89.4798 46.195C89.4798 40.4322 88.3515 34.8439 86.1277 29.5846C83.9786 24.5033 80.9007 19.9392 76.9806 16.0187C73.0597 12.0987 68.4961 9.02117 63.4143 6.87168C58.1555 4.64743 52.5667 3.51962 46.8044 3.51962ZM46.8044 0C72.3173 0 92.9994 20.6821 92.9994 46.195C92.9994 71.7079 72.3173 92.39 46.8044 92.39C21.2914 92.39 0.609375 71.7079 0.609375 46.195C0.609375 20.6821 21.2914 0 46.8044 0Z" fill="url(#paint0_linear_216_21)" fill-opacity="0.46" />
                                                    <path d="M68.019 28.5964L51.7135 19.1911C50.3548 18.4107 48.8153 18 47.2484 18C45.6815 18 44.142 18.4107 42.7834 19.1911L26.4734 28.5964C25.115 29.3828 23.9869 30.512 23.2018 31.8711C22.4168 33.2303 22.0024 34.7718 22 36.3414V55.1388C22.0027 56.708 22.4172 58.2491 23.2023 59.608C23.9873 60.9668 25.1153 62.0957 26.4734 62.8819L42.7825 72.2899C44.1411 73.0704 45.6807 73.4811 47.2475 73.4811C48.8144 73.4811 50.354 73.0704 51.7126 72.2899L68.019 62.8837C69.3772 62.0975 70.5052 60.9686 71.2902 59.6097C72.0752 58.2509 72.4898 56.7098 72.4925 55.1405V36.3414C72.4901 34.7718 72.0757 33.2303 71.2906 31.8711C70.5056 30.512 69.3774 29.3828 68.019 28.5964ZM68.158 55.1379C68.1569 55.9464 67.9434 56.7405 67.539 57.4406C67.1346 58.1408 66.5534 58.7224 65.8536 59.1274L49.5481 68.5335C48.8482 68.9357 48.0551 69.1473 47.248 69.1473C46.4408 69.1473 45.6478 68.9357 44.9479 68.5335L28.6389 59.1282C27.9391 58.7232 27.358 58.1416 26.9536 57.4414C26.5491 56.7413 26.3356 55.9473 26.3344 55.1388V36.3414C26.3356 35.5328 26.549 34.7387 26.9534 34.0386C27.3579 33.3385 27.9391 32.7568 28.6389 32.3519L44.9479 22.9466C45.6478 22.5445 46.4408 22.333 47.248 22.333C48.0551 22.333 48.8482 22.5445 49.5481 22.9466L65.8571 32.3519C66.5569 32.7569 67.138 33.3386 67.5424 34.0387C67.9468 34.7388 68.1603 35.5328 68.1616 36.3414L68.158 55.1379Z" fill="url(#paint1_linear_216_21)" />
                                                    <path d="M45.1929 44.0855L37.1435 37.2733C36.7047 36.902 36.1363 36.7202 35.5635 36.768C34.9906 36.8158 34.4602 37.0892 34.0889 37.528C33.7176 37.9668 33.5359 38.5352 33.5837 39.108C33.6314 39.6809 33.9048 40.2113 34.3437 40.5826L40.4379 45.7397L34.3437 50.8968C34.1264 51.0807 33.9474 51.3055 33.817 51.5585C33.6866 51.8115 33.6073 52.0877 33.5837 52.3714C33.56 52.655 33.5925 52.9405 33.6792 53.2116C33.7659 53.4827 33.9051 53.7341 34.0889 53.9514C34.2728 54.1687 34.4976 54.3477 34.7506 54.4781C35.0036 54.6085 35.2798 54.6878 35.5635 54.7114C35.8471 54.7351 36.1327 54.7026 36.4038 54.6159C36.6749 54.5292 36.9262 54.39 37.1435 54.2061L45.1929 47.3948C45.4333 47.1913 45.6264 46.9378 45.7589 46.6521C45.8914 46.3663 45.96 46.0551 45.96 45.7401C45.96 45.4252 45.8914 45.114 45.7589 44.8282C45.6264 44.5425 45.4333 44.289 45.1929 44.0855Z" fill="url(#paint2_linear_216_21)" />
                                                    <path d="M58.7575 50.3845H49.0055C48.4306 50.3845 47.8793 50.6129 47.4728 51.0194C47.0663 51.4259 46.8379 51.9773 46.8379 52.5522C46.8379 53.1271 47.0663 53.6784 47.4728 54.0849C47.8793 54.4914 48.4306 54.7198 49.0055 54.7198H58.7575C59.3324 54.7198 59.8838 54.4914 60.2903 54.0849C60.6968 53.6784 60.9252 53.1271 60.9252 52.5522C60.9252 51.9773 60.6968 51.4259 60.2903 51.0194C59.8838 50.6129 59.3324 50.3845 58.7575 50.3845Z" fill="url(#paint3_linear_216_21)" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear_216_21" x1="46.8044" y1="0" x2="46.8044" y2="92.39" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#DE4300" />
                                                            <stop offset="1" stop-color="#FBB625" />
                                                        </linearGradient>
                                                        <linearGradient id="paint1_linear_216_21" x1="47.2462" y1="18" x2="47.2462" y2="73.4811" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#FBB625" />
                                                            <stop offset="1" stop-color="#DE4300" />
                                                        </linearGradient>
                                                        <linearGradient id="paint2_linear_216_21" x1="39.7681" y1="36.7605" x2="39.7681" y2="54.7189" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#FBB625" />
                                                            <stop offset="1" stop-color="#DE4300" />
                                                        </linearGradient>
                                                        <linearGradient id="paint3_linear_216_21" x1="53.8815" y1="50.3845" x2="53.8815" y2="54.7198" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#FBB625" />
                                                            <stop offset="1" stop-color="#DE4300" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                            <p className="percent-text">4%</p>
                                            <p className="percent">Redistribution in BNB</p>
                                            <p className="text-main">This portion is designed to consistently
                                                reward holders. The choice of BNB, a
                                                cryptocurrency known for its stability and
                                                utility in the Binance ecosystem.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-box-section mt-5">
                            <div className="row align-items-center">
                                <div className="col-md-6 col-sm-6">
                                    <p className="heading-text">
                                        Lorem ipsum dolor sit amet, <br />consectetur adipiscing elit.
                                    </p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In rutrum condimentum luctus. Ut quis venenatis nibh, at condimentum orci. Vivamus a est iaculis, consequat nisi id, posuere lorem. Maecenas tincidunt imperdiet mauris, sed tempor eros bibendum a.</p>
                                    <a href="#" className="learn-more">Learn more</a>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <p className="top-text">Lorem Ipsum</p>
                                    <p className="bottom-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In rutrum condimentum luctus. Ut quis venenatis nibh, at condimentum orci. Vivamus a est iaculis, consequat nisi id, posuere lorem.</p>
                                    <p className="top-text">Lorem Ipsum</p>
                                    <p className="bottom-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In rutrum condimentum luctus. Ut quis venenatis nibh, at condimentum orci. Vivamus a est iaculis, consequat nisi id, posuere lorem.</p>
                                    <p className="top-text">Lorem Ipsum</p>
                                    <p className="bottom-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In rutrum condimentum luctus. Ut quis venenatis nibh, at condimentum orci. Vivamus a est iaculis, consequat nisi id, posuere lorem.</p>
                                </div>
                            </div>
                        </div>
                        <div className="tokenomics mt-5">
                            <p className="text-center toke-text">Tokenomics</p>
                            <p className="text-center token-info">Token information</p>
                            <div className="row progress-bar-section mt-4">
                                <div className="col-md-6 col-sm-6">
                                    <p className="presale">presale allocation</p>
                                    <p className="facilitates">Facilitates a decentralized distribution during the public launch.</p>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <p className="rupess">10,000,000,000/<span className="rupe-text">Tokens 100%</span></p>
                                    <div className="progress pogress-half">
                                        <div className="progress-bar" role="progressbar" style={{ width: "55%" }} aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row progress-bar-section mt-4">
                                <div className="col-md-6 col-sm-6">
                                    <p className="presale">Liquidity</p>
                                    <p className="facilitates">To Fortify trading</p>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <p className="rupess">3,000,000,000/<span className="rupe-text">Tokens 100%</span></p>
                                    <div className="progress pogress-half">
                                        <div className="progress-bar" role="progressbar" style={{ width: "15%" }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row progress-bar-section mt-4">
                                <div className="col-md-6 col-sm-6">
                                    <p className="presale">Staking Reward</p>
                                    <p className="facilitates">For incentivizing staking and liquidity provision.</p>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <p className="rupess">1,000,000,000/<span className="rupe-text">Tokens 10%</span></p>
                                    <div className="progress pogress-half">
                                        <div className="progress-bar" role="progressbar" style={{ width: "20%" }} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row progress-bar-section mt-4">
                                <div className="col-md-6 col-sm-6">
                                    <p className="presale">Partnerships And Intrations</p>
                                    <p className="facilitates">For potential partnerships, collaborations, and integrations</p>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <p className="rupess">10,000,000,000/<span className="rupe-text">Tokens 10%</span></p>
                                    <div className="progress pogress-half">
                                        <div className="progress-bar" role="progressbar" style={{ width: "3%" }} aria-valuenow="3" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row progress-bar-section mt-4">
                                <div className="col-md-6 col-sm-6">
                                    <p className="presale">Marketing And Community Engagement</p>
                                    <p className="facilitates">Funds marketing campaigns, partnerships, events, etc.</p>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <p className="rupess">500,000/<span className="rupe-text">Tokens 5%</span></p>
                                    <div className="progress pogress-half">
                                        <div className="progress-bar" role="progressbar" style={{ width: "5%" }} aria-valuenow="5" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row progress-bar-section mt-4">
                                <div className="col-md-6 col-sm-6">
                                    <p className="presale">Incentive Programs</p>
                                    <p className="facilitates">Allocate 2% for periodic incentive programs or events.</p>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <p className="rupess">200,000/<span className="rupe-text">Tokens 2%</span></p>
                                    <div className="progress pogress-half">
                                        <div className="progress-bar" role="progressbar" style={{ width: "2%" }} aria-valuenow="2" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row progress-bar-section mt-4">
                                <div className="col-md-6 col-sm-6">
                                    <p className="presale">Team And Product Developments</p>
                                    <p className="facilitates">Rewards the core team and supports ongoing development, enhancements, and security.</p>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <p className="rupess">500,000/<span className="rupe-text">Tokens 2%</span></p>
                                    <div className="progress pogress-half">
                                        <div className="progress-bar" role="progressbar" style={{ width: "5%" }} aria-valuenow="2" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="faq-section mt-5 mb-5">
                            <p className="faq-text text-center">FREQUENTLY ASKED QUESTIONS</p>
                            <div className="row">
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingOne">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                                                What is $KRBS Token?
                                            </button>
                                        </h2>
                                        <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                Is thoughtfully designed ecosystem created with the vision of amalgamating the best elements of transactional dividends, deflationary mechanisms, and staking benefits. By choosing $KRBS staking, you are aligning with a project that emphasizes both immediate rewards and long-term value generation. The road ahead is paved with innovation, commitment, and a relentless pursuit of delivering unparalleled value to our community.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                What is $KRBS Token?
                                            </button>
                                        </h2>
                                        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                Is thoughtfully designed ecosystem created with the vision of amalgamating the best elements of transactional dividends, deflationary mechanisms, and staking benefits. By choosing $KRBS staking, you are aligning with a project that emphasizes both immediate rewards and long-term value generation. The road ahead is paved with innovation, commitment, and a relentless pursuit of delivering unparalleled value to our community.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                What is $KRBS Token?
                                            </button>
                                        </h2>
                                        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                Is thoughtfully designed ecosystem created with the vision of amalgamating the best elements of transactional dividends, deflationary mechanisms, and staking benefits. By choosing $KRBS staking, you are aligning with a project that emphasizes both immediate rewards and long-term value generation. The road ahead is paved with innovation, commitment, and a relentless pursuit of delivering unparalleled value to our community.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
        </>
    );
}
export default Landing_page;