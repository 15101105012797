import React, { useEffect, useState } from "react";
import Landing_page from './Landing_page'

function Home() {
    const [raise, setRaise] = useState(0)
    return (
        <>
            <Landing_page setRaise={setRaise} />
        </>
    );
}

export default Home;