import { Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
//import './App.css';
import './theme/css/bootstrap.min.css';
import './theme/css/style.css';

import Header from './Components/Include/Header';
import Footer from './Components/Include/Footer';
import Home from './Components/Home';
import Staking from './Components/Staking';

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        {/* <Route path='/contact_us' element={<Contact_page />} /> */}
        {/* <Route path='/About' element={<AboutPage />} /> */}
        {/* <Route path='/OurStory' element={<StoryPage />} />  */}
        <Route path='/staking' element={<Staking />} />
      </Routes>
      {/* <Footer /> */}
    </>
  );
}

export default App;
