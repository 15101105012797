import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FaWallet } from "react-icons/fa";
import { AiOutlineMenu } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { Link } from "react-router-dom";

function Header() {
    const [show, setShow] = useState(false);
    const { chain } = useNetwork();
    const { chains, switchNetwork } = useSwitchNetwork();
    const { address } = useAccount();
    const { open } = useWeb3Modal();

    const handleClose = () => setShow(false);
    return (
        <header className="top-header">
            <nav className="navbar navbar-expand-sm">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src="./theme/images/loggtop.png" className="logo" alt="krabs" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="mynavbar">
                        <ul className="navbar-nav d-flex align-items-center">
                            <li className="nav-item ">
                                <Link className="nav-link active" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/staking">Staking</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contact_us">Contact</Link>
                            </li>
                        </ul>
                        <div className="button">
                            <a className="nav-link" href="javascript:void(0)">
                                <button type="button" className="top-button" onClick={() =>
                                    address
                                        ? chain?.id == chains[0]?.id || chain?.id == chains[1]?.id
                                            ? open()
                                            : switchNetwork?.(chains[0]?.id)
                                        : open()
                                }>
                                    {address ? (
                                        chain?.id == chains[0]?.id || chain?.id == chains[1]?.id ? (
                                            <>
                                                {`${address.substring(0, 6)}...${address.substring(
                                                    address.length - 4
                                                )}`}
                                            </>
                                        ) : (
                                            "Switch Network"
                                        )
                                    ) : (
                                        <>
                                            Connect <FaWallet />
                                        </>
                                    )}
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;