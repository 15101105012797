import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { FaWallet } from "react-icons/fa";

import Web3 from "web3";
import {
    prepareWriteContract,
    waitForTransaction,
    writeContract,
} from "@wagmi/core";
import { useAccount, useBalance, useNetwork, useSwitchNetwork } from "wagmi";
import {
    Presale_Contract_ABI,
    Presale_Contract_Address,
    USDT_Contract_ABI,
    USDT_Contract_Address,
    USDT_Token_Contract_Address,
    USDT_Token_Contract_Address_ABI,
    staking_Contract_ABI,
    staking_Contract_Address,
    Token_staking_Token_Contract_ABI,
    Token_staking_Token_Contract_Address,
    Token_Contract_ABI,
    Token_Contract_Address
} from "./Contract/Contract";
import toast from "react-hot-toast";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function Tab_page() {
    const [value, setValue] = React.useState(1);
    const [getBalance, setgetBalance] = useState(0);
    const [getKRBSBalance, setKRBSBalance] = useState(0);
    const [getBNB_value, setgetBNB_value] = useState("");
    const [showToken, setshowToken] = useState(0);
    const [spinner, setSpinner] = useState(false);
    const [totalRaisedinContract, settotalRaisedinContract] = useState(0);

    const { address } = useAccount();
    const { open } = useWeb3Modal();
    const { chain } = useNetwork();
    const { chains, switchNetwork } = useSwitchNetwork();
    const [get_userValue, setGet_userValue] = useState("");
    const [show_value, setshow_value] = useState(0);
    const [plan, setPlan] = useState(1);
    const [tokenId, setTokenId] = useState(1);
    const [usdRaisedinContract, setusdRaisedinContract] = useState(0);
    const EThwebSupply = new Web3("wss://ethereum-rpc.publicnode.com");



    // const [spinner, setSpinner] = useState(false);
    const data1 = useBalance({
        address: address || null,
    });

    console.log(data1?.data?.formatted, "balnc");
    const webSupply = new Web3("wss://ethereum-rpc.publicnode.com");
    const webSupply_Binance = new Web3("https://bsc-rpc.publicnode.com");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChange_value = (e) => {
        setgetBNB_value(e.target.value);
    };

    const getLive_Rate = async () => {
        try {

            const BSCcontractOf = new webSupply_Binance.eth.Contract(
                staking_Contract_ABI,
                staking_Contract_Address
            );
            let ETHcontractOf = new EThwebSupply.eth.Contract(
                Presale_Contract_ABI,
                Presale_Contract_Address
            );

            let BSCtokensold = await BSCcontractOf.methods.TokenSold().call();
            let BSCTokenPricePerusdt = await BSCcontractOf.methods
                .TokenPricePerUsdt()
                .call();
            BSCtokensold = (BSCtokensold / 1000000000).toString();
            BSCTokenPricePerusdt = webSupply.utils.fromWei(BSCTokenPricePerusdt.toString());
            let BSCusdtRaised = BSCtokensold / BSCTokenPricePerusdt;

            let ETHtokensold = await ETHcontractOf.methods.TokenSold().call();
            let ETHTokenPricePerusdt = await ETHcontractOf.methods
                .TokenPricePerUsdt()
                .call();
            ETHtokensold = (ETHtokensold / 1000000000).toString();
            ETHTokenPricePerusdt = webSupply.utils.fromWei(ETHTokenPricePerusdt.toString());
            let ETHusdtRaised = ETHtokensold / ETHTokenPricePerusdt;
            settotalRaisedinContract(Number(ETHusdtRaised) + Number(BSCusdtRaised));

            if (get_userValue) {
                let contractOf;
                if (chain?.id == 56) {
                    contractOf = new webSupply_Binance.eth.Contract(
                        staking_Contract_ABI,
                        staking_Contract_Address
                    );
                } else {
                    contractOf = new webSupply.eth.Contract(
                        Presale_Contract_ABI,
                        Presale_Contract_Address
                    );
                }


                if (plan == 1) {
                    let value = webSupply.utils.toWei(get_userValue.toString());
                    if (chain?.id == 56) {
                        //bnb
                        let getBlend_ProtocovalueperBNB = await contractOf.methods
                            .BNBToToken(value)
                            .call();

                        let result = getBlend_ProtocovalueperBNB / 1000000000;
                        result = result.toString()
                        setshow_value(result);
                    } else {
                        //eth
                        let getBlend_ProtocovalueperETH = await contractOf.methods
                            .ETHToToken(value)
                            .call();
                        let result = getBlend_ProtocovalueperETH / 1000000000;
                        result = result.toString()
                        setshow_value(result)
                    }
                } else if (plan == 2) {
                    if (chain?.id == 56) {
                        //usdt bnb
                        let value = webSupply_Binance.utils.toWei(get_userValue.toString());
                        let getBlend_ProtocovalueperBUSD = await contractOf.methods
                            .getValuePerUsdt(value)
                            .call();
                        let result = getBlend_ProtocovalueperBUSD / 1000000000;
                        result = result.toString()
                        setshow_value(result)
                    } else {
                        // usdt eth
                        let value = get_userValue * 1000000;
                        // console.log("value",value);
                        let getBlend_ProtocovalueperUSDT = await contractOf.methods
                            .getValuePerUsdt(value)
                            .call();
                        let result = getBlend_ProtocovalueperUSDT / 1000000000;
                        result = result.toString()
                        setshow_value(result)
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };
    // const balanceOf = async () => {
    //   try {
    //     let bnb_Balance = await webSupply_Binance.eth.getBalance(address);
    //     // console.log("bnb_Balance", bnb_Balance);
    //     bnb_Balance = bnb_Balance / 1000000000;

    //     setgetBalance(bnb_Balance.toString());

    //     let ContractOf = new webSupply.eth.Contract(
    //       staking_Contract_ABI,
    //       staking_Contract_Address
    //     );
    //     let value_BNB = webSupply.utils.toWei(getBNB_value.toString());
    //     let get_BNBTO_Token = await ContractOf.methods
    //       .BNBToToken(value_BNB)
    //       .call();
    //     console.log("get_BNBTO_Token", get_BNBTO_Token);
    //     get_BNBTO_Token = get_BNBTO_Token / 1000000000;
    //     // get_BNBTO_Token = webSupply.utils.fromWei(get_BNBTO_Token.toString());

    //     setshowToken(get_BNBTO_Token.toString());
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };


    const getBalanceuser = async () => {
        try {
            const BSCcontractOf = new webSupply_Binance.eth.Contract(
                staking_Contract_ABI,
                staking_Contract_Address
            );
            let ETHcontractOf = new webSupply.eth.Contract(
                Presale_Contract_ABI,
                Presale_Contract_Address
            );
            let contractOf;
            if (chain?.id == 56) {
                contractOf = new webSupply_Binance.eth.Contract(
                    staking_Contract_ABI,
                    staking_Contract_Address
                );
            } else {
                contractOf = new webSupply.eth.Contract(
                    Presale_Contract_ABI,
                    Presale_Contract_Address
                );
            }

            let tokensold = await contractOf.methods.TokenSold().call();
            let TokenPricePerusdt = await contractOf.methods
                .TokenPricePerUsdt()
                .call();
            tokensold = (tokensold / 1000000000).toString();
            TokenPricePerusdt = webSupply.utils.fromWei(TokenPricePerusdt.toString());
            let usdtRaised = tokensold / TokenPricePerusdt;
            setusdRaisedinContract(Number(usdtRaised).toFixed(5));

            if (address) {
                let contractOf;
                let contractOfToken;
                let contractOfKRBS
                if (chain?.id == 56) {
                    contractOfToken = new webSupply_Binance.eth.Contract(
                        USDT_Token_Contract_Address_ABI,
                        USDT_Token_Contract_Address
                    );
                    contractOf = new webSupply_Binance.eth.Contract(
                        staking_Contract_ABI,
                        staking_Contract_Address
                    );

                    contractOfKRBS = new webSupply_Binance.eth.Contract(
                        Token_staking_Token_Contract_ABI,
                        Token_staking_Token_Contract_Address
                    );

                } else {
                    contractOfToken = new webSupply.eth.Contract(USDT_Contract_ABI, USDT_Contract_Address);
                    contractOf = new webSupply.eth.Contract(
                        Presale_Contract_ABI,
                        Presale_Contract_Address
                    );

                    contractOfKRBS = new webSupply.eth.Contract(
                        Token_Contract_ABI,
                        Token_Contract_Address
                    );

                }
                if (plan == 1) {
                    if (chain?.id == 56) {
                        //bnb

                        let balanceOfToken = await webSupply_Binance.eth.getBalance(
                            address
                        );
                        balanceOfToken = webSupply_Binance.utils.fromWei(
                            balanceOfToken.toString()
                        );
                        console.log("balanceOfTokenbnb", balanceOfToken);
                        setgetBalance(parseFloat(balanceOfToken).toFixed(5));

                        let balanceToken = await contractOfKRBS.methods
                            .balanceOf(address)
                            .call();
                        balanceToken = balanceToken / 1000000000
                        setKRBSBalance(balanceToken)

                    } else {
                        //eth
                        let balanceOfToken = await webSupply.eth.getBalance(address);
                        balanceOfToken = webSupply.utils.fromWei(balanceOfToken.toString());
                        console.log("balanceOfTokeneth", balanceOfToken);
                        setgetBalance(parseFloat(balanceOfToken).toFixed(5));
                        let balanceToken = await contractOfKRBS.methods
                            .balanceOf(address)
                            .call();
                        balanceToken = balanceToken / 1000000000
                        setKRBSBalance(balanceToken)

                    }
                } else if (plan == 2) {
                    if (chain?.id == 56) {
                        //usdt bnb
                        let balanceToken = await contractOfToken.methods
                            .balanceOf(address)
                            .call();

                        balanceToken = webSupply_Binance.utils.fromWei(
                            balanceToken.toString()
                        );

                        setgetBalance(balanceToken);
                        let balanceTokenKRBS = await contractOfKRBS.methods
                            .balanceOf(address)
                            .call();
                        balanceTokenKRBS = balanceTokenKRBS / 1000000000
                        setKRBSBalance(balanceTokenKRBS)
                    } else {
                        //eth
                        let balanceToken = await contractOfToken.methods
                            .balanceOf(address)
                            .call();
                        let result = balanceToken / 1000000;
                        setgetBalance(result);
                        let balanceTokenKRBS = await contractOfKRBS.methods
                            .balanceOf(address)
                            .call();
                        balanceTokenKRBS = balanceTokenKRBS / 1000000000
                        setKRBSBalance(balanceTokenKRBS)
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };
    const staking_Amount = async () => {
        try {
            if (getBNB_value == "") {
                toast.error("Please Enter Amount First!");
                setSpinner(false);
            } else {
                if (getBNB_value > getBalance) {
                    toast.error("Insufficient Balance");
                    setSpinner(false);
                } else {
                    if (!address) {
                        toast.error("Please Connect Metamaske First!");
                    } else {
                        setSpinner(true);
                        let stakingValue;
                        stakingValue = webSupply.utils.toWei(getBNB_value.toString());
                        const { request } = await prepareWriteContract({
                            address: staking_Contract_Address,
                            abi: staking_Contract_ABI,
                            functionName: "BuyWithBNB",
                            args: [],
                            value: stakingValue.toString(),
                            account: address,
                        });
                        const { hash } = await writeContract(request);
                        const data = await waitForTransaction({
                            hash,
                        });
                        setSpinner(false);
                        toast.success("Purchase Successful");
                    }
                }
            }
        } catch (e) {
            console.log("Error", e);
            setSpinner(false);
        }
    };

    const buyEth = async () => {
        try {
            if (address) {
                if (get_userValue != "") {
                    if (get_userValue > parseFloat(data1?.data?.formatted).toFixed(5)) {
                        toast.error("Insufficient balance");
                        setSpinner(false);
                    } else {
                        setSpinner(true);
                        const { request } = await prepareWriteContract({
                            address: Presale_Contract_Address,
                            abi: Presale_Contract_ABI,
                            functionName: "BuyWithETH",
                            args: [],
                            value: webSupply_Binance.utils.toWei(get_userValue.toString()),
                            account: address,
                        });
                        const { hash } = await writeContract(request);
                        const data = await waitForTransaction({
                            hash,
                        });
                        toast.success("Transaction Completed");
                        setSpinner(false);
                    }
                } else {
                    toast.error("Please Enter Amount First");
                    setSpinner(false);
                }
            } else {
                toast.error("Connect Wallet!");
                setSpinner(false);
            }
        } catch (error) {
            console.log(error);
            setSpinner(false);
        }
    };
    const buyBNB = async () => {
        try {
            if (address) {
                if (get_userValue != "") {
                    if (get_userValue > parseFloat(data1?.data?.formatted).toFixed(5)) {
                        toast.error("Insufficient balance");
                        setSpinner(false);
                    } else {
                        setSpinner(true);
                        const { request } = await prepareWriteContract({
                            address: staking_Contract_Address,
                            abi: staking_Contract_ABI,
                            functionName: "BuyWithBNB",
                            args: [],
                            value: webSupply.utils.toWei(get_userValue.toString()),
                            account: address,
                        });
                        const { hash } = await writeContract(request);
                        const data = await waitForTransaction({
                            hash,
                        });
                        setTimeout(() => {
                            setSpinner(false);
                            toast.success("Transaction Completed");
                        }, 4000);
                    }
                } else {
                    toast.error("Please Enter Amount First");
                    setSpinner(false);
                }
            } else {
                toast.error("Connect Wallet!");
                setSpinner(false);
            }
        } catch (error) {
            console.log(error);
            setSpinner(false);
        }
    };

    const buyUSDT = async () => {
        try {
            if (address) {
                if (get_userValue != "") {
                    if (chain.id !== 56 && plan == 2) {
                        setSpinner(true);
                        let value = get_userValue * 1000000;
                        console.log("value", value);
                        const { request } = await prepareWriteContract({
                            address: USDT_Contract_Address,
                            abi: USDT_Contract_ABI,
                            functionName: "approve",
                            args: [Presale_Contract_Address, value],
                            account: address,
                        });
                        const { hash } = await writeContract(request);
                        const data = await waitForTransaction({
                            hash,
                        });
                        setTimeout(() => {
                            setSpinner(false);
                            toast.success("Approve SuccessFully");
                            Ethereum_Token();
                        }, 5000);
                    } else {
                        console.log("BNB");
                        setSpinner(true);
                        let value = webSupply_Binance.utils.toWei(get_userValue.toString());
                        const { request } = await prepareWriteContract({
                            address: USDT_Token_Contract_Address,
                            abi: USDT_Token_Contract_Address_ABI,
                            functionName: "approve",
                            args: [staking_Contract_Address, value],
                            account: address,
                        });
                        const { hash } = await writeContract(request);
                        const data = await waitForTransaction({
                            hash,
                        });
                        setTimeout(() => {
                            setSpinner(false);
                            toast.success("Approve SuccessFully");
                            Ethereum_Token();
                        }, 5000);
                    }
                } else {
                    toast.error("Please Enter Amount First");
                    setSpinner(false);
                }
            } else {
                toast.error("Connect Wallet!");
                setSpinner(false);
            }
        } catch (error) {
            setSpinner(false);
            console.log(error);
        }
    };

    const Ethereum_Token = async (UserID) => {
        try {
            if (chain.id !== 56 && plan == 2) {
                let value = get_userValue * 1000000
                setSpinner(true);
                const { request } = await prepareWriteContract({
                    address: Presale_Contract_Address,
                    abi: Presale_Contract_ABI,
                    functionName: "BuyTokenWithUSDT",
                    args: [value],
                    account: address,
                });
                const { hash } = await writeContract(request);
                const data = await waitForTransaction({
                    hash,
                });

                setTimeout(() => {
                    setSpinner(false);
                    toast.success("Transaction Completed");
                }, 4000);
            } else {
                let value = webSupply_Binance.utils.toWei(get_userValue.toString());

                setSpinner(true);
                const { request } = await prepareWriteContract({
                    address: staking_Contract_Address,
                    abi: staking_Contract_ABI,
                    functionName: "BuyTokenWithUSDT",
                    args: [value],
                    account: address,
                });
                const { hash } = await writeContract(request);
                const data = await waitForTransaction({
                    hash,
                });

                setTimeout(() => {
                    setSpinner(false);
                    toast.success("Transaction Completed");
                }, 4000);
            }
        } catch (error) {
            console.log(error);
            setSpinner(false);
        }
    };

    const changePlan = async (id, chainId, tokenId) => {
        setPlan(id);
        switchNetwork?.(chainId);
        setTokenId(tokenId);
    };

    useEffect(() => {
        getBalanceuser()
        getLive_Rate();
    }, [get_userValue, plan]);

    return (
        <>
            <div className="wallet mt-3">
                <div className="d-flex justify-content-center">
                    <div className="row main-rows-wallet justify-content-center">
                        <div className={tokenId == 1 ? "col-lg-6 col-sm-4 bnb-borders" : "col-lg-6 col-sm-4 wallet-border"} onClick={() => changePlan(1, chains[0]?.id, 1)}>
                            <div className="row">
                                <div className="col-sm-3">
                                    <img src="./theme/images/bnbpart.png" className="logo" alt="krabs" />
                                </div>
                                <div className="col-sm-9">
                                    <p className="bnb">BNB</p>
                                </div>
                            </div>
                        </div>
                        <div className={tokenId == 2 ? "col-lg-6 col-sm-4 bnb-borders" : "col-lg-6 col-sm-4 wallet-border"} onClick={() => changePlan(2, chains[0]?.id, 2)}>
                            <div className="row">
                                <div className="col-sm-3">
                                    <img src="./theme/images/usdc.png" className="logo" alt="krabs" />
                                </div>
                                <div className="col-sm-9">
                                    <p className="text-wallet">USDC</p>
                                </div>
                            </div>
                        </div>
                        <div className={tokenId == 3 ? "col-lg-6 col-sm-4 bnb-borders" : "col-lg-6 col-sm-4 wallet-border"} onClick={() => changePlan(1, chains[1]?.id, 3)}>
                            <div className="row">
                                <div className="col-sm-3">
                                    <img src="./theme/images/eth.png" className="logo" alt="krabs" />
                                </div>
                                <div className="col-sm-9">
                                    <p className="text-wallet"> ETH</p>
                                </div>
                            </div>
                        </div>
                        <div className={tokenId == 4 ? "col-lg-6 col-sm-4 bnb-borders" : "col-lg-6 col-sm-4 wallet-border"} onClick={() => changePlan(2, chains[1]?.id, 4)}>
                            <div className="row">
                                <div className="col-sm-3">
                                    <img src="./theme/images/usdt.png" className="logo" alt="krabs" />
                                </div>
                                <div className="col-sm-9">
                                    <p className="bnb">USDT</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="your-wallet-part mt-4">
                    <p className="text-center">
                        Your wallet : {parseFloat(getBalance.toString()).toFixed(5)}{" "}
                        {address ? plan == 1 ? (chain?.id == 56 ? "BNB" : "ETH") : (chain?.id == 56 ? "USDC" : "USDT") : tokenId == 1 ? "BNB" : tokenId == 2 ? "USDC" : tokenId == 3 ? "ETH" : "USDT"}
                    </p>
                    <p className="text-center">USD RAISED : ${parseFloat(totalRaisedinContract.toString()).toFixed(4)}/$500,000,</p>
                    <p className="text-center">
                        Your $KRBS Balance : {parseFloat(getKRBSBalance.toString()).toFixed(5)}{" "}
                        {plan == 1 ? (chain?.id == 56 ? "$KRBS" : "$KRBS") : "$KRBS"}
                    </p>
                </div>
                <div className="amount-bnb mt-4">
                    <p className="text-start">Amount in {address ? plan == 1 ? (chain?.id == 56 ? "BNB" : "ETH") : (chain?.id == 56 ? "USDC" : "USDT") : tokenId == 1 ? "BNB" : tokenId == 2 ? "USDC" : tokenId == 3 ? "ETH" : "USDT"}{" "}</p>
                    <p className="text-end" onClick={() =>
                        plan == 1
                            ? data1?.data?.formatted == undefined
                                ? setGet_userValue(0)
                                : setGet_userValue(
                                    Number(data1?.data?.formatted) -
                                    Number(0.001)
                                )
                            : getBalance == 0
                                ? setGet_userValue(0)
                                : setGet_userValue(
                                    Number(getBalance) - Number(0.0001)
                                )
                    }>
                        MAX
                    </p>
                    <input type="number" className="text-start" placeholder="0" value={get_userValue} onChange={(e) => setGet_userValue(e.target.value)} />
                </div>
                <div className="amount-bnb mt-3">
                    <div className="d-flex align-items-center">
                        <div className="col-md-6 col-sm-6">
                            <p className="text-start">Tokens</p>
                            <p className="text-start">{parseFloat(show_value).toFixed(1)}</p>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <img src="./theme/images/bootom-logo.png" className="float-end" alt="krabs" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="button-rows mt-4 d-flex justify-content-center">
                <div className="row row-gap-2">
                    <div className="col-lg-12 col-sm-6">
                        <a href="javascript:void(0);" className="top-button" onClick={() =>
                            plan == 1
                                ? chain?.id == 56
                                    ? buyBNB()
                                    : buyEth()
                                : plan == 2
                                    ? chain?.id == 56
                                        ? buyUSDT()
                                        : buyUSDT()
                                    : ""
                        }>
                            {spinner ? "Loading..." : "Buy $KRBS"}
                        </a>
                    </div>
                    <div className="col-lg-12 col-sm-6">
                        <a href="javascript:void(0);" className="top-button" onClick={() =>
                            address
                                ? chain?.id == chains[0]?.id || chain?.id == chains[1]?.id
                                    ? open()
                                    : switchNetwork?.(chains[0]?.id)
                                : open()
                        }>
                            {address ? (
                                chain?.id == chains[0]?.id || chain?.id == chains[1]?.id ? (
                                    <>
                                        {`${address.substring(0, 6)}...${address.substring(
                                            address.length - 4
                                        )}`}
                                    </>
                                ) : (
                                    "Switch Network"
                                )
                            ) : (
                                <>
                                    Connect <FaWallet />
                                </>
                            )}
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Tab_page;